// src/features/approval/approvalSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  IApproval,
  IApprovalItemComment,
  IApprovalItemCommentAicByEnum,
  IApprovalItemCommentAicColorEnum,
  IApprovalItemRevision,
  IApprovalItemRevisionAirStatusEnum,
  IApprovalStageCustomer,
  ICustomer,
  IStatus,
} from "../../services/thumbz-base-api";
import { thumbzApi } from "../../services/thumbz-api";

interface ApprovalState {
  manuallyChatOpen: boolean;
  selectedRevision: {
    air_id: string | null;
  };
  approvalStageCustomer: IApprovalStageCustomer | null;
  approval: IApproval | null;
  status: IStatus | null;
  loading: boolean;
  error: string | null;
  isMobile: boolean;
  approvedItems: string[];
}

const initialState: ApprovalState = {
  manuallyChatOpen: false,
  selectedRevision: {
    air_id: null,
  },
  approval: null,
  approvalStageCustomer: null,
  status: null,
  loading: false,
  error: null,
  isMobile: false,
  approvedItems: [],
};

export const fetchStatus = createAsyncThunk(
  "approval/fetchStatus",
  async (asc_id: string, { rejectWithValue }) => {
    try {
      const res = await thumbzApi.publicApi.apiControllerGetApprovalStatus({
        asc_id,
      });

      return res.status;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define the async thunk
export const fetchApproval = createAsyncThunk(
  "approval/fetchApproval",
  async (
    {
      asc_id,
      wrk_slug,
    }: {
      asc_id: string;
      wrk_slug: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const [resApprovalStageCustomer, reStatus] = await Promise.all([
        thumbzApi.publicApi.apiControllerGetApproval({ asc_id, wrk_slug }),
        thumbzApi.publicApi.apiControllerGetApprovalStatus({ asc_id }),
      ]);

      const revisionsPromiseArr = resApprovalStageCustomer.revisions.map(
        async (air_id) =>
          thumbzApi.publicApi.apiControllerGetApprovalRevision({
            air_id,
            asc_id,
            wrk_slug,
          })
      );

      const allRevisions = await Promise.all(revisionsPromiseArr);

      // Extrai os IDs dos itens que já estão aprovados
      const approvedItems = allRevisions
        .filter(
          ({ revision }) =>
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved
        )
        .map(({ revision }) => revision.air_id);

      const res: IApprovalStageCustomer = {
        approvalFlowStage: resApprovalStageCustomer.approvalFlowStage,
        approvalStage: resApprovalStageCustomer.approvalStage,
        asc_approved: resApprovalStageCustomer.asc_approved,
        asc_id: resApprovalStageCustomer.asc_id,
        customer: resApprovalStageCustomer.customer,
        revisions: allRevisions.map(({ revision }) => revision),
      };

      return {
        approvalStageCustomer: res,
        approval: resApprovalStageCustomer.approval,
        status: reStatus.status,
        approvedItems,
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const finishApproval = createAsyncThunk(
  "approval/finishApproval",
  async (data: IApprovalStageCustomer, { rejectWithValue }) => {
    try {
      const approvalStageCustomer =
        await thumbzApi.publicApi.apiControllerFinishApproval(data);

      const { status } =
        await thumbzApi.publicApi.apiControllerGetApprovalStatus(data);

      return {
        approval: approvalStageCustomer,
        status,
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveItem = createAsyncThunk(
  "approval/approveItem",
  async (
    { air_id }: { air_id: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as { approval: ApprovalState };
    const actualStatus = state.approval.approvalStageCustomer?.revisions.find(
      (revision) => revision.air_id === air_id
    )?.air_status;

    if (IApprovalItemRevisionAirStatusEnum.Approved === actualStatus) {
      return state.approval.approvalStageCustomer;
    }

    try {
      // Aprova o item atual
      dispatch(approvalSlice.actions.approveItem({ air_id }));

      // Adiciona o comentário sobre a aprovação
      dispatch(
        approvalSlice.actions.addComment({
          aic_by: IApprovalItemCommentAicByEnum.System,
          aic_color: IApprovalItemCommentAicColorEnum.Success, // Ajuste a cor conforme necessário
          air_id,
          comment: "Item aprovado pelo usuário. 👍",
          customer: null,
        })
      );

      // Retorna o estado atualizado
      return state.approval.approvalStageCustomer;
    } catch (error: any) {
      // Verifica se o erro tem a estrutura esperada
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(
          "Ocorreu um erro desconhecido ao aprovar o item"
        );
      }
    }
  }
);

export const reproveItem = createAsyncThunk(
  "approval/reproveItem",
  async (
    { air_id }: { air_id: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as { approval: ApprovalState };
    const actualStatus = state.approval.approvalStageCustomer?.revisions.find(
      (revision) => revision.air_id === air_id
    )?.air_status;

    if (IApprovalItemRevisionAirStatusEnum.Requested === actualStatus) {
      return state.approval.approvalStageCustomer;
    }

    try {
      // Reprova o item atual
      dispatch(approvalSlice.actions.reproveItem({ air_id }));

      // Adiciona o comentário sobre a reprovação
      dispatch(
        approvalSlice.actions.addComment({
          aic_by: IApprovalItemCommentAicByEnum.System,
          aic_color: IApprovalItemCommentAicColorEnum.Error, // Ajuste a cor conforme necessário
          air_id,
          comment: "Item reprovado pelo usuário. 👎",
          customer: null,
        })
      );

      // Retorna o estado atualizado
      return state.approval.approvalStageCustomer;
    } catch (error: any) {
      // Verifica se o erro tem a estrutura esperada
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(
          "Ocorreu um erro desconhecido ao reprovar o item"
        );
      }
    }
  }
);

export const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    openChat: (state) => {
      state.manuallyChatOpen = true;
    },
    closeChat: (state) => {
      state.manuallyChatOpen = false;

      if (state.isMobile) {
        const revisions = state.approvalStageCustomer?.revisions || [];
        const index = revisions.findIndex(
          (revision) =>
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Pending
        );

        if (index === -1) {
          return undefined;
        }

        state.selectedRevision = revisions[index];
      }
    },
    updateisMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    approveItem: (state, action: PayloadAction<{ air_id: string }>) => {
      const { approvalStageCustomer } = state;
      if (!approvalStageCustomer) return;

      approvalStageCustomer.revisions.forEach((revision) => {
        if (revision.air_id === action.payload.air_id) {
          if (
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved
          ) {
            return; // Se já está "Approved", não faz nada
          }

          revision.air_status = IApprovalItemRevisionAirStatusEnum.Approved;
        }
      });

      // selectedApprovla should be the next pending item
      const next = approvalSlice.selectors.selectNextPendingRevision({
        approval: state,
      })({
        actual_air_id: action.payload.air_id,
      });

      if (next.index !== undefined) {
        state.manuallyChatOpen = false;
        state.selectedRevision = approvalStageCustomer.revisions[next.index];
      }
    },
    reproveItem: (state, action: PayloadAction<{ air_id: string }>) => {
      const { approvalStageCustomer } = state;
      if (approvalStageCustomer) {
        approvalStageCustomer.revisions.forEach((revision) => {
          if (revision.air_id === action.payload.air_id) {
            if (
              revision.air_status ===
              IApprovalItemRevisionAirStatusEnum.Requested
            ) {
              return; // Se já está "Requested", não faz nada
            }

            revision.air_status = IApprovalItemRevisionAirStatusEnum.Requested;
            approvalSlice.actions.addComment({
              air_id: action.payload.air_id,
              comment: "Item reprovado pelo usuário. 👎",
              aic_by: IApprovalItemCommentAicByEnum.System,
              aic_color: IApprovalItemCommentAicColorEnum.Error,
              customer: null,
            });
          }
        });
      }

      if (state.isMobile) {
        state.manuallyChatOpen = true;
      }
    },
    addComment: (
      state,
      action: PayloadAction<{
        air_id: string;
        comment: string;
        aic_by: IApprovalItemCommentAicByEnum;
        customer: ICustomer | null;
        aic_color: IApprovalItemCommentAicColorEnum | null;
      }>
    ) => {
      const _revisionIndex = state.approvalStageCustomer?.revisions.findIndex(
        (revision) => revision.air_id === action.payload.air_id
      );

      if (_revisionIndex === -1 || _revisionIndex === undefined) {
        return;
      }

      const _revision = state.approvalStageCustomer?.revisions[_revisionIndex];

      if (!_revision) {
        return;
      }

      const newComment: Omit<IApprovalItemComment, "aic_id"> = {
        aic_by: action.payload.aic_by,
        aic_comment: action.payload.comment,
        customer: state.approvalStageCustomer?.customer || null,
        revision: { ..._revision }, // Faz uma cópia do objeto
        user: null,
        aic_color: action.payload.aic_color || undefined,
        aic_created_at: new Date().toISOString(),
        aic_updated_at: new Date().toISOString(),
        aic_deleted_at: null,
      };

      const updatedComments = _revision.air_comments
        ? [..._revision.air_comments, newComment]
        : [newComment];

      state.approvalStageCustomer!.revisions[_revisionIndex] = {
        ..._revision,
        // TODO: fix this ts-ignore
        // @ts-ignore
        air_comments: updatedComments,
      };
    },
    selectRevision: (state, action: PayloadAction<{ air_id: string }>) => {
      const _revisionIndex = state.approvalStageCustomer?.revisions.findIndex(
        (revision) => revision.air_id === action.payload.air_id
      );

      if (_revisionIndex === -1 || _revisionIndex === undefined) {
        return;
      }

      const _revision = state.approvalStageCustomer?.revisions[_revisionIndex];

      if (!_revision) {
        return;
      }

      state.manuallyChatOpen = false;
      state.selectedRevision = _revision;
    },
  },
  selectors: {
    selectApprovalStageCustomer: (state) => state.approvalStageCustomer,
    selectIsApproved: (state) =>
      state.approvalStageCustomer?.asc_approved || false,
    selectApprovalStage: (state) => state.approvalStageCustomer?.approvalStage,
    selectApproval: (state) => state.approval,
    selectApprovalResponses: (state) => state?.approval?.responses || [],
    selectApprovalStatus: (state) => state?.approval?.apv_status,
    selectWorkspace: (state) => state?.approval?.workspace,
    selectRevisions: (state) => state.approvalStageCustomer?.revisions || [],
    selectCustomer: (state) => state.approvalStageCustomer?.customer,
    selectOrganization: (state) => state?.approval?.workspace.organization,
    selectCount: (state) => ({
      total: state.approvalStageCustomer?.revisions.length || 0,
      approved:
        state.approvalStageCustomer?.revisions.filter(
          (revision) =>
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved
        ).length || 0,
      rejected:
        state.approvalStageCustomer?.revisions.filter(
          (revision) =>
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested
        ).length || 0,
      pending:
        state.approvalStageCustomer?.revisions.filter(
          (revision) =>
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Pending
        ).length || 0,
      alreadyRevised:
        state.approvalStageCustomer?.revisions.filter(
          (revision) =>
            revision.air_status ===
              IApprovalItemRevisionAirStatusEnum.Approved ||
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested
        ).length || 0,
      isAllRevised:
        state.approvalStageCustomer?.revisions.every(
          (revision) =>
            revision.air_status ===
              IApprovalItemRevisionAirStatusEnum.Approved ||
            revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested
        ) || false,
    }),
    status: (state) => state.status,
    selectItemStatus: (state: ApprovalState) => (air_id: string) => {
      const revision = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === air_id
      );

      return {
        is_approved:
          revision?.air_status === IApprovalItemRevisionAirStatusEnum.Approved,
        is_pending:
          revision?.air_status === IApprovalItemRevisionAirStatusEnum.Pending,
        is_reproved:
          revision?.air_status === IApprovalItemRevisionAirStatusEnum.Requested,
      };
    },
    selectNextPendingRevision:
      (state) =>
      ({
        actual_air_id,
      }: {
        actual_air_id: string;
      }): { index: number | undefined } => {
        const revisions = state.approvalStageCustomer?.revisions || [];
        const actualIndex = revisions.findIndex(
          (revision) => revision.air_id === actual_air_id
        );

        if (actualIndex === -1) {
          return {
            index: undefined,
          };
        }

        // Primeiro, procuramos o próximo item pendente a partir do índice atual
        for (let i = actualIndex + 1; i < revisions.length; i++) {
          if (
            revisions[i].air_status ===
            IApprovalItemRevisionAirStatusEnum.Pending
          ) {
            return {
              index: i,
            };
          }
        }

        // Se não encontrar nenhum item pendente após o atual, buscamos desde o início da lista
        for (let i = 0; i < revisions.length; i++) {
          if (
            revisions[i].air_status ===
            IApprovalItemRevisionAirStatusEnum.Pending
          ) {
            return {
              index: i,
            };
          }
        }

        // Caso não encontre nenhum item pendente
        return {
          index: undefined,
        };
      },
    selectFirstPendingRevision: (state) => {
      const revisions = state.approvalStageCustomer?.revisions || [];
      const index = revisions.findIndex(
        (revision) =>
          revision.air_status === IApprovalItemRevisionAirStatusEnum.Pending
      );

      if (index === -1) {
        return undefined;
      }

      return {
        index,
        revision: revisions[index],
      };
    },
    isRevisionSelected: (state) => (air_id: string) => {
      return state.selectedRevision?.air_id === air_id;
    },
    selectedRevision: (state) => {
      const selected = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === state.selectedRevision.air_id
      );
      return selected;
    },
    isChatOpen: (state) => {
      if (state.isMobile) {
        return state.manuallyChatOpen;
      }

      const { air_id } = state.selectedRevision;
      const selected = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === air_id
      );

      if (selected?.air_status === IApprovalItemRevisionAirStatusEnum.Requested)
        return true;

      const count = approvalSlice.selectors.revisionCommentsCount({
        approval: state,
      });
      if (count > 0) return true;
      if (state.manuallyChatOpen) return true;
      return false;
    },
    revisionComments: (state: ApprovalState) => {
      const selected = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === state.selectedRevision.air_id
      );
      return selected?.air_comments || [];
    },
    revisionCommentsCount: (state) => {
      const selected = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === state.selectedRevision.air_id
      );
      return (selected?.air_comments || []).filter(
        (comment) => comment.aic_by !== IApprovalItemCommentAicByEnum.System
      ).length;
    },
    isApproved: (state) => {
      return state.approvalStageCustomer?.asc_approved || false;
    },
    isAllRevised: (state) => {
      return state.approvalStageCustomer?.revisions.every(
        (revision) =>
          revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved ||
          revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested
      );
    },
    isItemDisabled: (state: ApprovalState) => (air_id: string) => {
      return state.approvedItems.includes(air_id);
    },
    selectItemResponses: (state) => {
      // TODO: use the selectedRevision instead of the state
      const selectedRevision = state.approvalStageCustomer?.revisions.find(
        (revision) => revision.air_id === state.selectedRevision.air_id
      );

      return selectedRevision?.approvalitem.responses || [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApproval.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchApproval.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalStageCustomer = action.payload.approvalStageCustomer;
        state.approval = action.payload.approval;
        state.status = action.payload.status;
        state.approvedItems = action.payload.approvedItems;

        const first = approvalSlice.selectors.selectFirstPendingRevision({
          approval: state,
        });

        state.selectedRevision =
          action.payload.approvalStageCustomer.revisions[first?.index ?? 0];
      })
      .addCase(fetchApproval.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Finish Approval
      .addCase(finishApproval.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(finishApproval.fulfilled, (state, action) => {
        state.approvalStageCustomer = action.payload.approval;
        state.status = action.payload.status;
        state.loading = false;
      })
      .addCase(finishApproval.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const approvalSelectors = approvalSlice.selectors;

export default approvalSlice.reducer;
