import {
  Stack,
  Avatar,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
  Divider,
} from "@mui/material";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";
import { ApprovalTypeFields } from "./responses/approval-type-fields";
import { Box } from "@mui/system";

export const DesktopHeader: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { total } = useAppSelector(approvalSlice.selectors.selectCount);
  const approval = useAppSelector(approvalSlice.selectors.selectApproval);
  const responses = useAppSelector(
    approvalSlice.selectors.selectApprovalResponses
  );
  const organization = useAppSelector(
    approvalSlice.selectors.selectOrganization
  );

  return (
    <Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        alignItems="center"
        p={2}
      >
        <Avatar
          alt="Logo"
          src={organization?.org_photo.ast_url || ""}
          sx={{ width: 40, height: 40 }}
        />
        <Stack>
          <Typography variant="body2" component="div">
            {approval?.apv_name || "Aprovação"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Total: {total} itens
          </Typography>
        </Stack>
      </Stack>

      {responses.length > 0 && (
        <Stack>
          <Divider />
          <Box px={2} py={1}>
            <ApprovalTypeFields responses={responses} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
