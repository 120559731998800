import React from "react";
import {
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { IApprovalResponse } from "../../services/thumbz-base-api";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { FieldLabel } from "../field-label";

interface FieldRendererProps {
  field: IApprovalResponse;
}

export const FieldRenderer: React.FC<FieldRendererProps> = ({ field }) => {
  const { approvalTypeField, apr_value } = field;

  const { atf_label, atf_options, fieldType } = approvalTypeField;
  const label = atf_label;

  switch (fieldType.fld_type) {
    case "text":
      return (
        <Box key={atf_label}>
          <FieldLabel label={label} />
          <Typography variant="body2">{apr_value || "-"}</Typography>
        </Box>
      );

    case "checkbox":
      return (
        <FormControl key={atf_label}>
          <FieldLabel label={label} />
          <FormGroup row>
            <Typography variant="body2">
              {(atf_options ?? [])
                .filter(
                  (option) =>
                    Array.isArray(apr_value) &&
                    (apr_value as string[]).includes(option)
                )
                .join(", ")}
            </Typography>
          </FormGroup>
        </FormControl>
      );

    case "select":
      return (
        <Box key={atf_label}>
          <FieldLabel label={label} />
          <Typography variant="body2">{apr_value || "-"}</Typography>
        </Box>
      );

    case "number":
      return (
        <Box key={atf_label}>
          <FieldLabel label={label} />
          <Typography variant="body2">{apr_value || "-"}</Typography>
        </Box>
      );

    case "date":
      return (
        <Box key={atf_label}>
          <Box sx={{ mb: -0.75 }}>
            <FieldLabel label={label} />
          </Box>
          <Typography variant="body2">
            {apr_value && typeof apr_value === "string"
              ? format(new Date(apr_value), "PPP", {
                  locale: ptBR,
                })
              : "-"}
          </Typography>
        </Box>
      );

    default:
      return (
        <Box key={atf_label}>
          <FieldLabel label={label} />
          <Typography variant="body2">{apr_value || "-"}</Typography>
        </Box>
      );
  }
};
