import React from "react";
import { Stack } from "@mui/system";
import { FieldRenderer } from "./field-renderer";
import { IApprovalResponse } from "../../services/thumbz-base-api";

interface ApprovalTypeFieldsProps {
  responses: IApprovalResponse[];
}

export const ApprovalTypeFields: React.FC<ApprovalTypeFieldsProps> = ({
  responses,
}) => {
  return (
    <Stack spacing={1}>
      {/* <pre>{JSON.stringify(responses, null, 2)}</pre> */}

      {responses.map((response) => {
        return <FieldRenderer key={response.apr_id} field={response} />;
      })}
    </Stack>
  );
};
