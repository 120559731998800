import React from "react";
import { Typography } from "@mui/material";

interface FieldLabelProps {
  label: string;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ label }) => {
  return (
    <Typography variant="caption" color={"GrayText"} mb={-0.75}>
      {label}
    </Typography>
  );
};
